.footer {
    background-color: rgba(255, 255, 255, 0);
    text-align: center;
    position: absolute;
    bottom: 0;
    font-size: 0.7rem;
    width: 100%;
    font-weight: 100;
    color: white;
  }
  