.navbar {
  background-color: transparent;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 100;
  text-align: center;
}

.navbar-brand {
  color: white;
  font-size: 1.75rem;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  /* padding-left: 5rem; */
}

.navbar-brand:hover {
  color: black;
  transition: all 0.3s ease-in-out;
}

.navbar-brand:visited {
  color: white;
  font-size: 1.5rem;
  text-decoration: none;
}

.navbar-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-item {
  margin-left: 10px;
}

.nav-link {
  color: white;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  padding-right: 5rem;
}

.nav-link:visited {
  color: white;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}


.nav-link:hover {
  color: black;
}

