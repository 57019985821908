.hero-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media (max-width: 768px) {
  .video-container video {
    padding-top: 20rem;
  }
}

.video-container video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
  z-index: -1;
}


.hero-content {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
text-align: center;;
}

.hero-title {
font-size: 4rem;
font-weight: 100;
margin: 0;
color: white;
}

.hero-subtitle {
font-size: 1.5rem;
font-weight: 400;
margin: 20px 0;
color: white;
}

.hero-button {
background-color: #e5e5e5;
border: none;
color: #1d2c3c;
font-size: 1.2rem;
font-weight: 600;
padding: 10px 20px;
border-radius: 25px;
cursor: pointer;
transition: all 0.3s ease-in-out;
}

.hero-button:hover {
background-color: #1d2c3c;
color: white;
}
  